<template>
  <div v-if="$store.state.me.role !== 'partner'" class="hs">
    <div class="form__item">
      <i class="search_ico">
        <SearchIco/>
      </i>
      <input type="text" class="hs__input" placeholder="Поиск..." v-model="search" @input="startSearch">
    </div>
    <div class="hs__drop" v-if="search.length > 0" v-click-outside="vcoConfig">
      <span class="hs__loading" v-if="timeout"><Spinner/></span>
      <template v-for="(result, index) in results">
        <div class="hs__section" v-if="result.items.length" :key="index">
          <label class="hs__section__title">
            <span>{{ result.title }}</span>
            <span class="count">{{ result.items.length }}</span>
          </label>
          <ul v-if="result.title === 'Существующие компании'" class="hs__section__list">
            <!--  :class="{ forgotten: item.tags.find(el => el.name === 'Забытый клиент') }" -->
            <li
              v-for="(item, index) in result.items"
              :key="index"
              @click="route(result.route, { id: item.id || item._id})"
              @click.right="routeBlank(result.route, { id: item.id || item._id})"
              @click.middle="routeBlank(result.route, { id: item.id || item._id})"
            >
              <a>
                <span v-if="item.tags.find(el => el.name === 'Забытый клиент')" class="tag tag--green">{{ item.name }}</span>
                <span v-else class="tag tag--red">{{ item.name }}</span>
              </a>
              <a>{{ item.responsible_user.name }}</a>
            </li>
          </ul>
          <ul v-else class="hs__section__list">
            <li
              v-for="(item, index) in result.items"
              :key="index"
              @click="route(result.route, { id: item.id || item._id})"
              @click.right="routeBlank(result.route, { id: item.id || item._id})"
              @click.middle="routeBlank(result.route, { id: item.id || item._id})"
            >
              <a v-if="item.name">{{ item.name }}</a>
              <a v-else>
                <span class="lsg-tag">{{ getStatus(item.status) }}</span>
                {{ item.company ? item.company.name : `Без наименования ${index + 1}` }}
              </a>
            </li>
          </ul>
        </div>
      </template>
      <template v-if="noResults && search.length > 2 && !timeout">
        <div class="hs__section">
          <label class="hs__section__title">
            <span>Совпадений не найдено</span>
          </label>
          <ul class="hs__section__list">
            <li><router-link :to="{ name: 'add_company' }" @click.native="resetSearch">Создать компанию</router-link></li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SearchIco from '@/components/svg/search'
import Spinner from '@/components/svg/spinner'
import SEARCH from '@/graphql/queries/Search.gql'

export default {
  name: 'HeaderSearch',
  components: { SearchIco, Spinner },
  data () {
    return {
      search: '',
      results: null,
      timeout: null,
      vcoConfig: {
        middleware: event => {
          return !event.target.classList.contains('hs__input')
        },
        handler: () => {
          this.search = ''
        },
        events: ['dblclick', 'click'],
        isActive: true
      }
    }
  },
  computed: {
    noResults () {
      if (!this.results) return -1
      const result = this.results.filter(block => {
        return Boolean(block.items.length)
      })
      return result.length ? 0 : 1
    }
  },
  methods: {
    route (name, params) {
      this.$router.push({ name: name, params: params })
      this.resetSearch()
    },
    routeBlank (name, params) {
      const routeData = this.$router.resolve({ name: name, params: params })
      window.open(routeData.href, '_blank')
    },
    getStatus (status) {
      if (this.$store.state.leadStatusListClear) {
        for (let i = 0; i < this.$store.state.leadStatusListClear.length; i++) {
          if (this.$store.state.leadStatusListClear[i].value === status) {
            return this.$store.state.leadStatusListClear[i].title
          }
        }
      }
    },
    resetSearch () {
      this.search = ''
    },
    getRouteByKey (key) {
      switch (key) {
        case 'allContracts':
          return 'contract'
        case 'allContacts':
          return 'contact'
        case 'allCompanies':
          return 'company'
        case 'allLeads':
          return 'trade'
        case 'privateCompanies':
          return 'company'
      }
    },
    getTitleByKey (key) {
      switch (key) {
        case 'allContracts':
          return 'Договора'
        case 'allContacts':
          return 'Контакты'
        case 'allCompanies':
          return 'Компании'
        case 'allLeads':
          return 'Сделки'
        case 'privateCompanies':
          return 'Существующие компании'
      }
    },
    startSearch () {
      if (this.search.length < 3) {
        this.results = []
        return
      }
      clearTimeout(this.timeout)
      this.results = null
      this.timeout = setTimeout(() => {
        this.$apollo.mutate({
          mutation: SEARCH,
          variables: {
            q: this.search
          },
          update: (store, { data }) => {
            this.results = []
            Object.keys(data).forEach(key => {
              if (key === 'privateCompanies' && (this.$store.state.me.role === 'manager' || this.$store.state.me.role === 'headmanager')) {
                this.results.push({
                  title: this.getTitleByKey(key),
                  route: this.getRouteByKey(key),
                  items: data[key].slice(0, 5),
                  length: data[key].length - 5
                })
              } else if (key !== 'privateCompanies') {
                this.results.push({
                  title: this.getTitleByKey(key),
                  route: this.getRouteByKey(key),
                  items: data[key].slice(0, 5),
                  length: data[key].length - 5
                })
              }
            })
            this.timeout = null
          }
        })
      }, 500)
    }
  }
}
</script>

<style lang="stylus">
.hs
  max-width 500px
  flex 1
  position relative

  &__input
    height 100%
    padding: 13px 20px;
    width: 100%
    outline none
    background: $white;
    border: 1px solid $darker;
    box-sizing: border-box;
    border-radius: 10px;
    font-family font_regular
    margin 0
    transition height 0.3s
    font-size: 15px;
    line-height: 18px;

    &:focus
      background alpha($gray, 30%)
      box-shadow none

  &__loading
    display flex
    padding 20px

  &__drop
    absolute left bottom
    transform translateY(calc(100% + 15px))
    background $white
    box-shadow: rgba($c4E555A, 0.2) 0 20px 30px;
    border-radius: 10px;
    width 100%
    max-width 500px
    max-height 90vh
    overflow-y auto
    z-index 99

    +below(768px)
      position fixed
      left 30px
      width calc(100% - 60px)
      min-width auto

  &__section
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    padding-bottom: 20px;
    border-top: 1px solid $gray

    &__title
      display flex
      align-items center
      padding 15px 20px

      span:not(.count)
        font-weight: bold;
        font-size: 17px;
        line-height: 21px;
        color: $black;

      .count
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: rgba($black, 0.5);
        margin-left 5px

    &__list
      list-style none
      padding 0
      display flex
      flex-direction column
      width 100%

      li
        display flex
        align-items center
        justify-content flex-start
        width 100%
        padding 2px 0

        a
          font-weight: normal;
          font-size: 15px;
          line-height: 18px;
          color: $black;
          width 100%
          padding 4px 20px

          &:hover
            color $orange
            background $gray

  &__icon
    absolute left 28px
    display flex
    align-items center
    justify-content center
    width 16px
    height 16px

  .form__item
    display flex
    align-items center
    position relative
    margin 0 !important
    width 100%
    height 100%

    .search_ico
      absolute right
      width 45px
      height 45px
      display flex
      align-items center
      justify-content center

  svg
    width 20px
    height auto

</style>
