<template>
  <header class="header" :class="{ shrink: !isSideBarExpanded}">
    <a href="#" @click="changeSideBarState" class="as__toggle">
      <SideBarIco/>
    </a>
    <mq-layout :mq="['sm', 'xsm']" class="mobile_logo"><Logo/></mq-layout>
    <mq-layout :mq="['md', 'lg', 'xlg', 'xl']" class="header__search"><header-search/></mq-layout>
    <div class="header__content">
      <mq-layout :mq="['sm', 'xsm']" class="mobile_search_ico" :class="{ 'is-active': searchShow }">
        <span @click="searchShow = !searchShow"><SearchIco/></span>
      </mq-layout>
      <div v-if="$route.path === '/' && $store.state.me.role !== 'partner'" @click="changeDesktop" class="actions__item manager-desktop">
        <span v-if="$store.state.managerDesktop" class="actions__item__icon">
          <HomeIco/>
        </span>
        <span v-else class="actions__item__icon">
          <DocPlus/>
        </span>
      </div>
      <div @click="changeTourMenu" class="actions__item icon-only">
        <span title="Тур" class="actions__item__icon">
          <QuestionTour/>
        </span>
      </div>
      <div class="header__actions actions menu has_messages lsg-popover" :class="{ 'menu__expanded': notificationContent }">
        <div class="actions__item has_submenu" @click="subMenu = false, notificationContent = !notificationContent">
          <span class="actions__item__icon--position" :class="{'actions__item__icon' : $store.state.unprocessesSupports > 0}">
            <Ring/>
          </span>
          <span>События</span>
        </div>
        <div class="actions__content submenu" :class="{ 'active': notificationContent }">
          <ul @click="notificationContent = !notificationContent">
            <li><router-link v-if="$store.state.unprocessesSupports > 0" :to="{ name: 'support' }">У вас {{ $store.state.unprocessesSupports }} нов{{ $store.state.unprocessesSupports === 1 ? 'ое' : 'ых' }} уведомлени{{ $store.state.unprocessesSupports === 1 ? 'е' : 'й' }}</router-link></li>
            <li v-for="event in $store.state.lastEvents" :key="event.id"><router-link style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden" :to="{ name: 'events' }">{{ event.title }}</router-link></li>
            <li><router-link  :to="{ name: 'events' }">Ещё...</router-link></li>
            <!-- <li><a href="#">Раздел в разработке</a></li>
            <li><a href="#">Улучшения на сайте</a></li> -->
          </ul>
        </div>
      </div>
      <div class="header__user user_mini menu lsg-popover" :class="{ 'menu__expanded': subMenu }">
        <div class="user_mini__main has_submenu" @click="subMenu = !subMenu, notificationContent = false">
          <img :src="`${baseUrl}/avatar?user=${this.$store.state.me._id}`" alt="user">
          <span>{{ $store.state.me.name }}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9L12 15L18 9" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="user_mini__menu submenu" :class="{ 'active': subMenu }">
          <ul>
            <li>
              <router-link :to="{ name: 'cabinet' }">Настройки</router-link>
            </li>
            <li><a href="#" @click="logout">Выйти</a></li>
            <!-- <li v-if="email"><a href="#" @click="goBack">Вернуться</a></li> -->
          </ul>
        </div>
      </div>
    </div>
    <mq-layout :mq="['xsm', 'sm']" class="header__search_mob" v-if="searchShow">
      <header-search/>
    </mq-layout>
  </header>
</template>

<script>
import Ring from '@/components/svg/ring'
import HeaderSearch from '../ui/HeaderSearch'
import SideBarIco from '@/components/svg/sidebar'
import Logo from '@/components/svg/new/LogoIco'
import SearchIco from '@/components/svg/search'
import QuestionTour from '@/components/svg/QuestionTour'
import LOGIN_USER from '@/graphql/mutations/LoginUser.gql'
import DocPlus from '@/components/svg/doc_plus'
import HomeIco from '@/components/svg/home'

import GraphQLHelper from '@/helpers/GraphQLHelper'
const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Header',
  components: {
    HeaderSearch,
    Ring,
    SideBarIco,
    Logo,
    DocPlus,
    HomeIco,
    SearchIco,
    QuestionTour
  },
  data () {
    return {
      subMenu: false,
      notificationContent: false,
      scrollPosition: null,
      searchField: '',
      searchShow: false,
      baseUrl: process.env.VUE_APP_HTTP,
      email: null,
      password: '123'
    }
  },
  computed: {
    isSideBarExpanded () {
      return this.$store.state.sideBarState === 'expanded'
    },
    isAdmin () {
      return this.$route.meta.subscribe && this.$store.state.me ? this.$store.state.me.role === 'admin' : false
    }
  },
  async beforeCreate () {
    await this.$store.dispatch('unprocessesSupports', this)
    this.email = localStorage.getItem('myEmail')
  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll)
    document.addEventListener('click', this.updatePopovers)
  },
  methods: {
    changeDesktop () {
      this.$store.state.managerDesktop = !this.$store.state.managerDesktop
    },
    changeTourMenu () {
      if (this.isAdmin) {
        this.$store.state.showTourMenu = !this.$store.state.showTourMenu
      } else {
        this.$emit('tourRepeat')
      }
    },
    logout (e) {
      e.preventDefault()
      localStorage.removeItem('admin-token')
      localStorage.removeItem('myEmail')
      this.$store.dispatch({ type: 'removeAuthToken' }).then(() => {
        this.$router.push('/auth')
      })
    },

    changeSideBarState (e) {
      e.preventDefault()
      let value = this.isSideBarExpanded ? 'nonexpanded' : 'expanded'
      this.$store.dispatch({ type: 'setSideBarState', value: value })
    },

    updateScroll () {
      this.scrollPosition = window.scrollY
    },

    updatePopovers (e) {
      if (event.target.closest('.lsg-popover')) return

      this.notificationContent = false
      this.subMenu = false
    },

    async createTrade () {
      this.$store.dispatch({ type: 'switchShowAddTradeModal' })
      // await this.$apollo.mutate({
      //   mutation: ADD_LEAD,
      //   variables: {
      //     input: {
      //       status: 'Первичный контакт'
      //     }
      //   },
      //   update: (store, { data: { addLead } }) => {
      //     if (addLead && addLead._id) {
      //       this.$router.push({ name: 'trade', params: { id: addLead._id } })
      //     }
      //   }
      // }).catch(error => {
      //   _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
      //     this.$notify({
      //       group: 'lsg-notify',
      //       text: item
      //     })
      //   })
      // })
    },

    createContact () {
      this.$router.push({ name: 'add_contact' })
    },

    createCompany () {
      this.$router.push({ name: 'add_company' })
    },
    async goBack () {
      localStorage.setItem('apollo-token', localStorage.getItem('admin-token'))
      await this.$apollo.mutate({
        mutation: LOGIN_USER,
        variables: {
          email: this.email,
          password: this.password
        },
        update: (store, { data: { loginUser } }) => {
          this.$store.dispatch({
            type: 'setAuthToken',
            token: loginUser
          }).then(() => {
            this.$router.push('/admin#carousel')
            this.$router.go()
            localStorage.removeItem('admin-token')
            localStorage.removeItem('myEmail')
          })
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: 'Ошибка при возвращении'
          })
        })
      })
    }
  }
}
</script>

<style lang="stylus">
.header
  fixed top right
  width calc(100% - 280px)
  height 80px
  display flex
  align-items center
  justify-content space-between
  background $white
  padding 0 20px
  box-shadow: 0 0 10px rgba($darker, 0.17);
  transition all 0.3s
  z-index 999

  .as__toggle
    display none
    padding 0
    height 80px
    width 80px
    margin 0

  +below(980px)
    position initial
    left auto
    right auto
    width 100%
    border-top 1px solid $darkgray

  +below(768px)
    padding 0 15px 0 0

    .as__toggle
      display inline-flex

  +below(540px)
    height 60px
    padding 0

    .as__toggle
      height 60px
      width 60px
      margin 0

  &.shrink
    width calc(100% - 100px)

    +below(980px)
      width 100%

.header__content
  display flex
  align-items center
  justify-content flex-end

  +below(540px)
    flex 1

.header__actions

  svg
    width 16px

    #has_notify
      display none

  &.menu__expanded

    svg
      path
        fill $orange

.actions__messages
  display flex
  height 60px
  padding 0 15px
  cursor pointer

  svg
    path
      fill lighten($dark, 50%)
      transition all 0.3s

  &:hover
    opacity 1

    svg
      path
        fill $orange

  +below(540px)
    height 60px

.actions__item
  margin-left 15px
  display flex
  align-items center
  background: $gray;
  border-radius: 10px;
  padding 12px 20px
  flex-shrink 0
  cursor pointer

  &__icon
    width 20px
    height 20px
    margin-right 10px
    display flex
    align-items center
    justify-content center
    position relative

    &:last-child {
      margin 0
    }

  +below(540px)
    background none
    padding 0 10px
    justify-content center
    margin 0
    height 60px
    border-radius 0
    font-size 0

    &__icon
      margin 0

      svg
        width 100%
        height auto
        transition all 0.3s

        path
          stroke $dark
          transition all 0.3s

      &:hover
        svg
          path
            stroke $orange

.has_messages
  .actions__item__icon--position
    display flex
    justify-content center
    align-items center
    margin-right 10px

  .actions__item__icon
    &::before
      content ''
      absolute top -5px right
      width 7px
      height 7px
      border 2px solid $white
      border-radius 50%
      background $orange

.header__user
  margin-left 30px

  img
    width 50px
    height 50px
    object-fit contain
    object-position center
    border-radius 100%
    border 1px solid $gray
  span
    margin-left 15px
    margin-right 10px

  +below(540px)
    margin 0
    padding 0 15px 0 5px

    a
      display none

    img
      margin 10px
      width 30px
      height 30px

  +below(400px)

    img
      margin 0

.user_mini__main
  height 60px
  display flex
  align-items center
  cursor pointer

  +below(1100px)
    span
      display none

  +below(540px)
    svg
      display none

    img
      border 1px solid $darkgray

.menu
  position relative
  height 60px
  display flex
  align-items center

.submenu
  opacity 0
  pointer-events none
  absolute top 90px right
  width 250px
  background $white
  box-shadow alpha($dark, 20%) 0 20px 30px
  overflow hidden
  transition all 0.3s
  border-radius: 10px;
  z-index 50

  ul
    padding 0
    margin 0
    list-style none

    li
      margin 0
      padding 0

      a
        display block
        padding 15px 30px
        border-top 1px solid $gray

        &:hover
          background $gray

      &:first-child
        border-top none

  &.active
    top 70px
    opacity 1
    pointer-events auto

  +below(540px)
    top 70px

    &.active
      top 60px

.actions__content
  width 300px

  ul
    li
      a
        font-size 0.9em

.header__add
  .button
    height 40px
    padding 5px 15px
    font-size 0.9em
    min-height 40px

    svg
      path
        stroke $white

  +below(768px)
    .button
      width 40px
      padding 5px
      font-size 0px

      svg
        margin 0

  +below(540px)
    margin-left 10px

    .button
      width 40px
      height 40px
      min-height 40px

.header__scrolled
  height 60px
  box-shadow alpha($dark, 7%) 0 5px 10px

  .header__logo
    a
      width 60px
      height 60px

      #logo
        width 45px

  .header__menu
    a
      width 60px
      height 60px

  .actions__messages
    height 60px

  .user_mini__main
    height 60px

  .submenu
    top 70px

    &.active
      top 60px

.mobile_logo
  display flex
  align-items center
  height 60px
  border-right 1px solid $darkgray
  max-width 100px
  padding-right 10px

  svg
    width 100%
    height 20px
    fill $dark

    .logo_orange
      fill $orange

.header__search
  flex 1

.mobile_search_ico
  height 60px
  padding 0 10px
  display flex
  align-items center
  justify-content center

  span
    display flex

  svg
    width 20px
    height auto
    fill $dark

  &.is-active
    svg
      fill $orange

.header__search_mob
  absolute left top 60px
  background $white
  width 100%
  z-index 100

.manager-desktop
  svg > path
    stroke $orange
    stroke-width 2
</style>
