<template>
  <svg class="spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <path d="M43.9 25.1c0-10.3-8.4-18.7-18.7-18.7 -10.3 0-18.7 8.4-18.7 18.7h4.1c0-8.1 6.5-14.6 14.6-14.6 8.1 0 14.6 6.5 14.6 14.6H43.9z">
      <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"></animateTransform>
    </path>
  </svg>
</template>

<script>
export default {
  name: 'Spinner'
}
</script>

<style lang="stylus">
.spinner
  width 20px
  fill $orange
</style>
